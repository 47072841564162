import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['userConfig'])
  },
  mounted() {
    this.setSiteTitle(), this.setSiteIcon();
  },
  methods: {
    setSiteTitle() {
      document.title = this.userConfig?.siteTitle || 'JayMuShui 图床 | Img ';
    },
    setSiteIcon() {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = this.userConfig?.siteIcon || '/logo.png';
      document.head.appendChild(link);
    }
  }
};